.login-input-label {
  position: absolute;
  top: 0;
  left: 10px;
  transition: all 0.3s ease;
}

.input-wrapper:focus-within .login-input-label,
.login-input-label.lifted {
  transform: translateY(-10px);
  font-size: 0.75rem;
}

.login-input {
  border-bottom: 1px solid black;
  outline: none;
  padding-top: 16px;
  padding-bottom: 2px;
  background: none;
}
